import React from "react";

export const DonePatientList = ({ finishedPatients }) => {
  return (
    <div className="card mb-4">
      <div
        className="card-header d-flex justify-content-between"
        style={{ backgroundColor: "#96C8A2" }}
      >
        <h5 className="fw-bold text-center">Done patient List</h5>
      </div>
      <div
        className="card-body pe-0 ps-0"
        style={{ backgroundColor: "#E0EEEE" }}
      >
        <div className="w-100">
          <div className="d-flex flex-wrap ps-2">
            {finishedPatients.map((patient, index) => (
              <div
              key={index}
                className="text-center text-white px-2 py-1 mb-2 me-1"
                style={{
                  backgroundColor:
                    patient.patient_in == 1
                      ? patient.is_paid == ""
                        ? "#bec719"
                        : "#00a19a"
                      : "#7bada8",
                  borderRadius: "5px",
                  border:
                    patient.is_online == 1 && patient.is_place_date == "yes"
                      ? "2px solid green"
                      : patient.is_online == 1
                      ? "2px solid black"
                      : patient.is_place_date == "yes"
                      ? "2px solid blue"
                      : "",
                }}
                role="button"
              >
                {patient.token_number} - {patient?.patient?.name || "MKITE"}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
