import React from "react";
import { Button, Modal } from "react-bootstrap";
import { Terms } from "../../../components/Terms";
import { Privacy } from "../../../components/privacy";
import { RefundPolicy } from "../../../components/RefundPolicy";

export const TermsModel = ({ showModal, type, handleClose }) => {
  return (
    <div>
      <Modal size="lg" show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="h6">{type}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ height: "550px", overflowY: "auto" }}>
            {type == "Terms Of Services" ? (
              <Terms />
            ) : type == "Privacy Policy" ? (
              <Privacy />
            ) : (
              <RefundPolicy />
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => handleClose()}
            className="px-5"
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
