import React, { useEffect, useState } from "react";
import AudioPlayerWithRepeat from "../AudioPlayerWithRepeat";
import alarm from "../../../image/alarm.mp3";
import { Button } from "antd";
import { AiOutlinePlus, AiOutlineStop } from "react-icons/ai";

const DashboardBreak = ({
  handleBreakModel,
  isLoading,
  permission,
  handleBreakButtonClick,
  seconds,
  setSeconds,
}) => {
  const [alarmShow, setAlarmShow] = useState(false);
  const [alarmOn, setAlarmOn] = useState(true);
  const offAlarm = () => {
    setAlarmOn(false);
  };
  const formatTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60)
      .toString()
      .padStart(2, "0");
    const sec = (timeInSeconds % 60).toString().padStart(2, "0");
    return `${minutes}:${sec}`;
  };

  useEffect(() => {
    if (seconds <= 0) {
      if (alarmShow === true) {
        setAlarmShow(false);
      }
      return;
    }
    if (seconds < 60) {
      if (alarmShow === false) {
        setAlarmShow(true);
      }
    }
    // Set up the timer
    const timer = setInterval(() => {
      setSeconds((prevSeconds) => prevSeconds - 1);
    }, 1000);
    // Clean up the timer
    return () => clearInterval(timer);
  }, [seconds]);

  return (
    <>
      <div className="row justify-content-center">
        <div className="col-md-4 text-center">
          <h1>Break Timer</h1>
          <div className="mt-4">
            <div
              className="bg-danger pt-5"
              style={{
                width: "150px",
                height: "150px",
                borderRadius: "50%",
                marginLeft: "25%",
              }}
            >
              <h1 className="text-white">{formatTime(seconds)}</h1>
            </div>
          </div>
        </div>
      </div>
      <div className="row justify-content-center px-2 mt-4">
        <div className="col-md-3 text-center pb-2">
          <Button
            className="btn btn-warning fw-bold w-100"
            type="button"
            onClick={handleBreakModel}
            disabled={isLoading}
          >
            <AiOutlinePlus /> Add time
          </Button>
        </div>
        {alarmShow && alarmOn && (
          <div className="col-md-3 text-center mb-2">
            <AudioPlayerWithRepeat src={alarm} offAlarm={offAlarm} />
          </div>
        )}
        <div className="col-md-3 text-center pb-2">
          {permission > 0 && (
            <Button
              className="btn btn-success fw-bold w-100"
              type="button"
              onClick={(e) => {
                setAlarmShow(false);
                handleBreakButtonClick(e);
              }}
              disabled={isLoading}
            >
              <span className="">
                {isLoading && (
                  <span className="spinner-border spinner-border-sm mr-1"></span>
                )}
                <AiOutlineStop /> Stop Break
              </span>
            </Button>
          )}
        </div>
      </div>
    </>
  );
};

export default DashboardBreak;
