import React from "react";
import { BiSkipNext } from "react-icons/bi";

export const NextButton = ({ handleNext, isLoading }) => {
  return (
    <button
      className="btn btn-primary mb-3 mt-2"
      type="button"
      onClick={handleNext}
      disabled={isLoading}
      style={{ width: "160px" }}
    >
      <span className="fs-5 fw-bold">
        {isLoading && (
          <span className="spinner-border spinner-border-sm mr-1"></span>
        )}
        <BiSkipNext /> Next
      </span>
    </button>
  );
};
