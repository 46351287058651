import React, { useEffect, useState } from "react";
import { Dashboard } from "./Dashboard";
import axios from "axios";
import { AUTH_TOKEN, BASE_URL } from "../../utils";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
import Header from "./Header";
import { ShiftModel } from "./components/ShiftModel";
import { MdDeleteOutline } from "react-icons/md";
import { FaRegEdit } from "react-icons/fa";
import Head from "./components/Head";

export const Shift = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [mode, setMode] = useState("Add");
  const [list, setList] = useState([]);
  const [isModel, setIsModel] = useState(false);
  const [formData, setFormData] = useState({
    shift_id: "",
    name: "",
    from: "",
    end_time: "",
    replace_amount: "",
    avg_consult_time: "",
    map_location: "",
    max_patient: "",
    hospital_name: "",
    doctor_name: "",
    address: "",
    fees: "",
    affter_patient: "",
    booking_time: "",
    is_booking: true,
    weekdays: [],
    booking_days: 2,
    auto_reserve: 1,
  });
  const [permissions, setPermissions] = useState({
    add: 0,
    edit: 0,
    delete: 0,
  });
  const userPer = JSON.parse(localStorage.getItem("role_permissions") || "[]");

  const handleSubmit = (e) => {
    e.preventDefault();
    const fData = new FormData(e.target);
    const offDays = fData.getAll("days");
    const offDates = fData.getAll("offDates");
    const data = {
      shift_id: formData.shift_id,
      name: formData.name,
      from: formData.from,
      end_time: formData.end_time,
      replace_amount: formData.replace_amount,
      avg_consult_time: formData.avg_consult_time,
      map_location: formData.map_location,
      max_patient: formData.max_patient,
      hospital_name: formData.hospital_name,
      doctor_name: formData.doctor_name,
      address: formData.address,
      fees: formData.fees,
      affter_patient: formData.affter_patient,
      booking_time: formData.booking_time,
      is_booking: formData.is_booking,
      booking_days: formData.booking_days,
      auto_reserve: formData.auto_reserve,
      weekdays: offDays,
      offDates: offDates,
    };
    setIsLoading(true);
    axios
      .post(`${BASE_URL}/doctor/addShift`, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${AUTH_TOKEN}`,
        },
      })
      .then((response) => {
        const setData = {
          shift_id: "",
          name: "",
          from: "",
          end_time: "",
          replace_amount: "",
          avg_consult_time: "",
          map_location: "",
          max_patient: "",
          hospital_name: "",
          doctor_name: "",
          address: "",
          fees: "",
          affter_patient: "",
          booking_time: "",
          booking_days: 2,
          auto_reserve: 1,
          is_booking: true,
        };
        setFormData(setData);
        setIsLoading(false);
        fatchList();
        setIsModel(!isModel);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Failed to add the shift. Please try again.",
        });
        setIsLoading(false);
      });
  };

  const fatchList = async () => {
    try {
      setIsLoading(true);
      const response = await axios.post(
        `${BASE_URL}/doctor/listShift`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${AUTH_TOKEN}`,
          },
        }
      );

      if (response.status === 200) {
        setList(response.data.data);
        setIsLoading(false);
      } else {
        console.error("API request failed");
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching the API:", error);
    }
  };

  const editShift = (el) => {
    const setData = {
      shift_id: el.id,
      name: el.name,
      from: el.from,
      end_time: el.end_time,
      replace_amount: el.replace_amount,
      avg_consult_time: el.avg_consult_time,
      map_location: el.map_location,
      max_patient: el.max_patient,
      hospital_name: el.hospital_name,
      doctor_name: el.doctor_name,
      address: el.address,
      fees: el.fees,
      affter_patient: el.affter_patient,
      booking_time: el.booking_time,
      is_booking: el.is_booking,
      booking_days: el.booking_days,
      auto_reserve: el.auto_reserve,
      weekdays: el?.weekdays || [],
      offDates: el?.offDates || [],
    };
    setFormData(setData);
    setMode("Edit");
    setIsModel(!isModel);
  };

  const deleteNotice = async (shift_id) => {
    try {
      setIsLoading(true);
      const response = await axios.post(
        `${BASE_URL}/doctor/deleteShift`,
        { shift_id: shift_id },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${AUTH_TOKEN}`,
          },
        }
      );

      if (response.status === 200) {
        fatchList();
        setIsLoading(false);
      } else {
        console.error("API request failed");
      }
    } catch (error) {
      console.error("Error fetching the API:", error);
    }
  };

  const modelToggle = () => {
    setIsModel(!isModel);
    setMode("Add");
    const setData = {
      shift_id: "",
      name: "",
      from: "",
      end_time: "",
      replace_amount: "",
      avg_consult_time: "",
      map_location: "",
      max_patient: "",
      hospital_name: "",
      doctor_name: "",
      address: "",
      fees: "",
      affter_patient: "",
      booking_time: "",
      is_booking: true,
      booking_days: 2,
      auto_reserve: 1,
    };
    setFormData(setData);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  useEffect(() => {
    fatchList();
    if (userPer) {
      const addPermission = userPer.some((perm) => perm.id === 28);
      const editPermission = userPer.some((perm) => perm.id === 29);
      const deletePermission = userPer.some((perm) => perm.id === 30);
      setPermissions({
        add: addPermission,
        edit: editPermission,
        delete: deletePermission,
      });
    }
  }, []);

  return (
    <div className="dashboard">
      <div
        className="d-md-none"
        style={{
          width: "100%",
          backgroundColor: "black",
          color: "wheat",
          marginBottom: "70px",
        }}
      >
        <Header />
      </div>
      <div className="d-flex">
        <Dashboard />
        <div className="w-100">
          <Head />
          <div className="main-container my-3 px-lg-3 px-1">
            <div className="d-flex justify-content-center mt-3">
              <div className="card card-body">
                <div className="d-flex justify-content-between">
                  <h4>Shift List</h4>

                  {permissions.add > 0 && (
                    <button
                      className="btn btn-primary btn-sm"
                      onClick={modelToggle}
                    >
                      Add Shift
                    </button>
                  )}
                </div>
                <div className="table-responsive">
                  <table className="table table-bordered mt-3">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Start Time</th>
                        <th>Consultation Time</th>
                        <th>Maximum Patient</th>
                        <th>Doctor Name</th>
                        <th>Online Booking</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {list.map((item, index) => (
                        <tr key={item.id}>
                          <td>{item.name}</td>
                          <td>{item.from}</td>
                          <td>{item.avg_consult_time} Min</td>
                          <td>{item.max_patient}</td>
                          <td>{item.doctor_name}</td>
                          <td>
                            {item.is_booking == 1 ? (
                              <span className=" text-success px-2 py-1 rounded">
                                Active
                              </span>
                            ) : (
                              <span className=" text-danger px-2 py-1 rounded">
                                Inactive
                              </span>
                            )}
                          </td>
                          <td>
                            <div className="d-flex">
                              {permissions.edit > 0 && (
                                <button
                                  onClick={() => editShift(item)}
                                  className="btn btn-warning btn-sm ml-2"
                                  title="edit"
                                >
                                  <FaRegEdit />
                                </button>
                              )}
                              &nbsp;
                              {permissions.delete > 0 && (
                                <>
                                  {list.length > 1 && (
                                    <button
                                      onClick={() => deleteNotice(item.id)}
                                      className="btn btn-danger btn-sm mr-2"
                                      title="delete"
                                    >
                                      <MdDeleteOutline />
                                    </button>
                                  )}
                                </>
                              )}
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isModel && (
        <ShiftModel
          show={isModel}
          handleSubmit={handleSubmit}
          formData={formData}
          handleInputChange={handleInputChange}
          modelToggle={modelToggle}
          isLoading={isLoading}
          mode={mode}
        />
      )}
    </div>
  );
};
