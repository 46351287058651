import React from "react";

export const DashboardTotal = ({
  total,
  pending,
  skip,
  fees,
  online,
  replace,
}) => {
  return (
    <div className="row">
      <div className="col-6 col-lg-2">
        <div className="alert alert-success text-center py-1 px-1">
          <p className="h2 py-0 my-0">{total}</p>
          <p className="py-0 my-0">Total Patient</p>
        </div>
      </div>
      <div className="col-6 col-lg-2">
        <div className="alert alert-primary text-center py-1 px-1">
          <p className="h2 py-0 my-0">{pending}</p>
          <p className="py-0 my-0">Pending Patient</p>
        </div>
      </div>
      <div className="col-6 col-lg-2">
        <div className="alert alert-warning text-center py-1 px-1">
          <p className="h2 py-0 my-0">{skip}</p>
          <p className="py-0 my-0">Skip Patient</p>
        </div>
      </div>
      <div className="col-6 col-lg-2">
        <div className="alert alert-danger text-center py-1 px-1">
          <p className="h2 py-0 my-0">{fees}</p>
          <p className="py-0 my-0">Total Fees</p>
        </div>
      </div>
      <div className="col-6 col-lg-2">
        <div
          className="alert alert-success text-center py-1 px-1"
          style={{ background: "#cdc4e7" }}
        >
          <p className="h2 py-0 my-0">{online}</p>
          <p className="py-0 my-0">Online Booking</p>
        </div>
      </div>
      <div className="col-6 col-lg-2">
        <div
          className="alert alert-info text-center py-1 px-1"
          style={{ background: "#62ed7366" }}
        >
          <p className="h2 py-0 my-0">{replace}</p>
          <p className="py-0 my-0">Replace Booking</p>
        </div>
      </div>
    </div>
  );
};
