import React, { useEffect, useState } from "react";
import Head from "./components/Head";
import { Dashboard } from "./Dashboard";
import Header from "./Header";
import { Col, Row } from "antd";
import ChartData from "./components/ChartData";
import ChartData2 from "./components/ChartData2";
import ChartData3 from "./components/ChartData3";
import ChartData4 from "./components/ChartData4";
import authService from "../../services/authService";
import Utils from "../../utils/Utils";

const AnalyticalReport = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(false);
  const [date, setDate] = useState(Utils.todayDate());

  const fatchData = () => {
    setIsLoading(true);
    const payload = { date: date };
    authService
      .analyticalReports(payload)
      .then((res) => res.json())
      .then((data) => {
        setData(data?.data || []);
      })
      .catch((err) => {
        //console.log("Error fetching the API:", err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  useEffect(() => {
    fatchData();
  }, []);

  return (
    <div className="dashboard">
      <div
        className="d-md-none"
        style={{
          width: "100%",
          backgroundColor: "black",
          color: "wheat",
          marginBottom: "70px",
        }}
      >
        <Header />
      </div>
      <div className="d-flex">
        <Dashboard />
        <div className="w-100">
          <Head />
          <div className="main-container my-3 px-lg-3 px-1">
            <Row gutter={[16, 16]} style={{ paddingBottom: "10px" }}>
              <Col xs={14} md={6}>
                <input
                  type="date"
                  name="date"
                  defaultValue={date}
                  onChange={(e) => setDate(e.target.value)}
                  className="form-control bg-light text-dark mt-3"
                  placeholder="Please select a Date"
                  required
                />
              </Col>
              <Col xs={10} md={3}>
                <div className="text-center">
                  <button
                    onClick={() => fatchData()}
                    type="button"
                    className="btn btn-success mt-3 px-5"
                    disabled={isLoading}
                  >
                    {isLoading ? "Loading..." : "search"}
                  </button>
                </div>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col xs={24} md={14}>
                <ChartData
                  title={"Weekly Booking Report"}
                  itemData={data}
                  isLoading={isLoading}
                />
              </Col>
              <Col xs={24} md={10}>
                <ChartData2
                  title={"Weekly Booking Report with %"}
                  itemData={data}
                  isLoading={isLoading}
                />
              </Col>
              <Col xs={24} md={8}>
                <ChartData4
                  title={"Waiting Avg Time Report"}
                  itemData={data}
                  isLoading={isLoading}
                />
              </Col>
              <Col xs={24} md={8}>
                <ChartData3
                  title={"Today Booking Report with %"}
                  isLoading={isLoading}
                  todayOnline={data.todayOnline}
                  todayOffline={data.todayOffline}
                />
              </Col>
              <Col xs={24} md={8}>
                <ChartData3
                  title={"Monthly Booking Report with %"}
                  isLoading={isLoading}
                  todayOnline={data.onlineBmonthly}
                  todayOffline={data.replaceBmonthly}
                  type="Replace Patients"
                />
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnalyticalReport;
