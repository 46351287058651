import React from "react";
import { ImCancelCircle } from "react-icons/im";

export const SkipButton = ({ handleSkip, isLoading, setIsRecallToken }) => {
  return (
    <button
      className="btn btn-warning mb-4 mt-2 px-4"
      type="button"
      onClick={handleSkip}
      disabled={isLoading}
      style={{ width: "160px" }}
    >
      <span className="fs-5 fw-bold" onClick={() => setIsRecallToken(null)}>
        {isLoading && (
          <span className="spinner-border spinner-border-sm mr-1"></span>
        )}
        <ImCancelCircle /> Skip
      </span>
    </button>
  );
};
