import React, { useEffect, useState } from "react";
import { Dashboard } from "./Dashboard";
import axios from "axios";
import { AUTH_TOKEN, BASE_URL, USER_DETAILS } from "../../utils";
import "react-toastify/dist/ReactToastify.css";
import Header from "./Header";

import { Button, Modal } from "react-bootstrap";
import EditInvoiceModel from "./components/EditInvoiceModel";
import FeeModel from "./components/FeeModel";
import { MdDeleteOutline } from "react-icons/md";
import { FaRegEdit, FaRegEye } from "react-icons/fa";
import Head from "./components/Head";
import Utils from "../../utils/Utils";

const ShowFeesModal = ({ show, showData, modelToggle }) => {
  return (
    <Modal show={show} onHide={modelToggle}>
      <Modal.Body className="p-0">
        <div className="bg-light p-3 ">
          <div className="d-flex justify-content-between">
            <p className="my-0">Invoice No. - {showData?.invoice_no} </p>
            <p className="my-0">Time - {showData?.invoice_date} </p>
          </div>
          <hr className="my-0" />
          <div className="d-flex justify-content-between">
            <p className="my-0">
              Patient Name - {showData?.token?.patient?.name}
            </p>
            <p className="my-0">Age - {showData?.token?.patient?.dob}</p>
          </div>
        </div>
        <div className="p-3">
          <table className="table table-bordered">
            {showData.items &&
              showData.items.map((item, index) => (
                <tr key={index}>
                  <td>{item.title}</td>
                  <td className="text-end">- {item.price}</td>
                </tr>
              ))}
            <tr className="bg-light">
              <th className="text-end">Discount </th>
              <th className="text-end">- {showData.discount}</th>
            </tr>
            <tr className="bg-light">
              <th className="text-end">Total Amount</th>
              <th className="text-end">- {showData.amount}</th>
            </tr>
          </table>
          <div className="text-end">
            <Button
              className="py-0 px-5"
              variant="secondary"
              onClick={modelToggle}
            >
              Close
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

const Invoice = () => {
  const [list, setList] = useState([]);
  const [isModel, setIsModel] = useState(false);
  const [today, setToday] = useState(null);
  const [editData, setEditData] = useState([]);
  const [showModel, setShowModel] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [shifts, setShift] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    fromDate: "",
    toDate: "",
    shift_id: localStorage.getItem("shift_id") ?? USER_DETAILS.active_shift_id,
  });
  const [permissions, setPermissions] = useState({
    add: 0,
    edit: 0,
    delete: 0,
    assign: 0,
  });
  const userPer = JSON.parse(localStorage.getItem("role_permissions") || "[]");

  const fetchList = async () => {
    try {
      const response = await axios.post(
        `${BASE_URL}/doctor/invoice/list`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${AUTH_TOKEN}`,
          },
        }
      );

      if (response.status === 200) {
        setList(response.data.data);
      } else {
        console.error("API request failed");
      }
    } catch (error) {
      console.error("Error fetching the API:", error);
    }
  };

  const editFees = (el) => {
    setEditData(el);
    setShowEditModal(true);
  };

  const viewFees = (el) => {
    setEditData(el);
    setShowModel(!showModel);
  };

  const deleteFees = async (invoice_id) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/doctor/invoice/delete`,
        { invoice_id: invoice_id },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${AUTH_TOKEN}`,
          },
        }
      );

      if (response.status === 200) {
        fetchList();
      } else {
        console.error("API request failed");
      }
    } catch (error) {
      console.error("Error deleting the invoice:", error);
    }
  };

  const getShiftList = async () => {
    try {
      setIsLoading(true);
      const response = await axios.post(
        `${BASE_URL}/doctor/listShift`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${AUTH_TOKEN}`,
          },
        }
      );

      if (response.status === 200) {
        setShift(response.data.data);
        setIsLoading(false);
        if (response.data.data.length == 1) {
          localStorage.setItem("shift_id", response.data.data[0].id);
        }
      } else {
        console.error("API request failed");
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching the API:", error);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    fetchList();
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const reSetForm = () => {
    var todate = Utils.todayDate();
    setFormData({
      ...formData,
      ["fromDate"]: todate,
      ["toDate"]: todate,
    });
    setToday(todate);
  };

  useEffect(() => {
    reSetForm();
    getShiftList();
    fetchList();
    if (userPer) {
      const addPermission = userPer.some((perm) => perm.id === 19);
      const editPermission = userPer.some((perm) => perm.id === 20);
      const deletePermission = userPer.some((perm) => perm.id === 21);
      setPermissions({
        add: addPermission,
        edit: editPermission,
        delete: deletePermission,
      });
    }
  }, []);

  const closeEditModal = () => {
    setShowEditModal(!showEditModal);
    setEditData([]);
  };
  return (
    <div className="dashboard">
      <div
        className="d-md-none"
        style={{
          width: "100%",
          backgroundColor: "black",
          color: "wheat",
          marginBottom: "60px",
        }}
      >
        <Header />
      </div>
      <div className="d-flex">
        <Dashboard />
        <div className="w-100">
          <Head />
          <div className="main-container my-3 px-lg-3 px-1">
            <div className="d-flex justify-content-center">
              <div className="card card-body">
                <h5>Invoice List</h5>
                <hr />
                <div className="d-flex justify-content-between">
                  <div className="">
                    <form className="row mb-3" onSubmit={handleSubmit}>
                      <div className="col-md-3 form-group">
                        <label htmlFor="fromDate" className="k-label">
                          From:
                        </label>
                        <input
                          type="date"
                          name="fromDate"
                          className="form-control"
                          value={formData.fromDate}
                          onChange={(e) => handleInputChange(e)}
                          pattern="dd-mm-yyyy"
                        />
                      </div>
                      <div className="col-md-3 form-group">
                        <label htmlFor="toDate" className="k-label">
                          To:
                        </label>
                        <input
                          type="date"
                          name="toDate"
                          className="form-control"
                          value={formData.toDate}
                          onChange={(e) => handleInputChange(e)}
                          pattern="dd-mm-yyyy"
                        />
                      </div>
                      {shifts.length > 1 && (
                        <div className="col-md-2 form-group">
                          <label htmlFor="Shift" className="k-label">
                            Shifts
                          </label>
                          <select
                            className="form-control"
                            placeholder="Shift Name"
                            onChange={(e) => handleInputChange(e)}
                            name="shift_id"
                            value={formData.shift_id}
                          >
                            <option value="">Select Shift</option>
                            {shifts.map((item, i) => (
                              <option value={item.id} key={`shift-${i}`}>
                                {item.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      )}
                      <div className="col-md-4">
                        <button
                          type="submit"
                          className="btn btn-primary px-lg-4 mt-4 me-2"
                          disabled={isLoading}
                        >
                          {isLoading && (
                            <span className="spinner-border spinner-border-sm mr-1"></span>
                          )}{" "}
                          Submit
                        </button>
                        <button
                          onClick={() => reSetForm()}
                          type="reset"
                          className="btn btn-info px-lg-4 mt-4"
                        >
                          Reset
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="table-responsive">
                  <table className="table table-bordered mt-3 text-center">
                    <thead>
                      <tr>
                        <th>Patient Name</th>
                        <th>Token No.</th>
                        <th>Amount</th>
                        <th>Discount</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {list.map((item) => (
                        <tr key={item.id}>
                          <td>{item?.token?.patient?.name}</td>
                          <td>{item?.token?.token_number}</td>
                          <td>{item.amount}</td>
                          <td>{item.discount}</td>
                          <td>
                            <div className="d-flex justify-content-center">
                              <button
                                onClick={() => viewFees(item)}
                                className="btn btn-secondary btn-sm"
                                title="View"
                              >
                                <FaRegEye />
                              </button>

                              {permissions.edit > 0 && (
                                <button
                                  onClick={() => editFees(item)}
                                  className="btn btn-warning btn-sm mx-1"
                                  title="edit"
                                >
                                  <FaRegEdit />
                                </button>
                              )}
                              {permissions.delete > 0 && (
                                <button
                                  onClick={() => deleteFees(item.id)}
                                  className="btn btn-danger btn-sm"
                                  title="delete"
                                >
                                  <MdDeleteOutline />
                                </button>
                              )}
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FeeModel
        show={isModel}
        editData={editData}
        getPatientList={fetchList}
        style={{ width: "450px" }}
      />
      <ShowFeesModal
        show={showModel}
        showData={editData}
        modelToggle={() => setShowModel(false)}
        style={{ width: "450px" }}
      />
      <EditInvoiceModel
        show={showEditModal}
        handleClose={() => closeEditModal()}
        editData={editData}
        refreshList={fetchList}
      />
    </div>
  );
};

export default Invoice;
