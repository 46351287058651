import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import Offcanvas from "react-bootstrap/Offcanvas";
import axios from "axios";
import { LuMenuSquare } from "react-icons/lu";
import {
  BiBell,
  BiClinic,
  BiDollar,
  BiHome,
  BiListCheck,
  BiLogOut,
  BiPlus,
  BiTime,
  BiUser,
  BiListUl,
  BiRightArrowAlt,
  BiDownArrowAlt,
  BiBarChart,
  BiCog,
  BiWallet,
  BiMessage,
  BiShieldPlus,
  BiChart,
} from "react-icons/bi";
import logo from "../../image/logo.png";
import {
  AUTH_KEY,
  AUTH_TOKEN,
  TOKEN_KEY,
  USER_DETAILS,
  BASE_URL,
} from "../../utils";

const MenuIcon = ({ icon }) => {
  const icons = {
    BiPlus: <BiPlus size={25} className="me-1" />,
    BiBell: <BiBell size={25} className="me-1" />,
    BiClinic: <BiClinic size={25} className="me-1" />,
    BiDollar: <BiDollar size={25} className="me-1" />,
    BiHome: <BiHome size={25} className="me-1" />,
    BiListCheck: <BiListCheck size={25} className="me-1" />,
    BiTime: <BiTime size={25} className="me-1" />,
    BiUser: <BiUser size={25} className="me-1" />,
    BiListUl: <BiListUl size={25} className="me-1" />,
    BiBarChart: <BiBarChart size={25} className="me-1" />,
    BiCog: <BiCog size={25} className="me-1" />,
    BiWallet: <BiWallet size={25} className="me-1" />,
    BiMessage: <BiMessage size={25} className="me-1" />,
    BiShieldPlus: <BiShieldPlus size={25} className="me-1" />,
  };

  return icons[icon] || <BiListUl size={25} className="me-1" />;
};

const Header = () => {
  const [showSidebar, setShowSidebar] = useState(false);
  const url = `../doctor-profile/${USER_DETAILS.name}/${USER_DETAILS.doctor_id}`;
  const [menu, setMenu] = useState(
    JSON.parse(localStorage.getItem("menu") || "[]")
  );
  const [visibleSubmenu, setVisibleSubmenu] = useState(null);
  useEffect(() => {
    if (!AUTH_TOKEN) {
      window.location.href = "../";
    }
    if (menu.length == 0) {
      getPermission();
    }
  }, []);

  const logout = async () => {
    try {
      await axios.post(
        `${BASE_URL}/doctor/logout`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${AUTH_TOKEN}`,
          },
        }
      );
    } catch (error) {
      console.error("Error fetching the API:", error);
    }

    localStorage.removeItem(TOKEN_KEY);
    localStorage.removeItem(AUTH_KEY);
    localStorage.removeItem("role_permissions");
    localStorage.removeItem("menu");

    window.location.href = "../";
  };

  const getPermission = async () => {
    try {
      const response = await axios.post(
        `${BASE_URL}/role/permissions`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${AUTH_TOKEN}`,
          },
        }
      );

      const permissions = response.data.data.permissions;
      const role_permissions = response.data.data.role_permissions;
      localStorage.setItem(
        "role_permissions",
        JSON.stringify(role_permissions)
      );
      localStorage.setItem("menu", JSON.stringify(permissions));
      setMenu(permissions);
    } catch (error) {
      console.error("Error fetching the API:", error);
    }
  };

  const handleMenuClick = (key) => {
    setVisibleSubmenu(visibleSubmenu === key ? null : key);
  };

  const handleCloseSidebar = () => setShowSidebar(false);
  const handleShowSidebar = () => setShowSidebar(true);

  const renderMenuItem = (item, index) => {
    const menuItemStyle = {
      color: "#52a19a",
      backgroundColor: "white",
      cursor: "pointer",
    };

    return (
      <div key={item.key}>
        {item.submenu && item.submenu.length ? (
          <>
            <div
              className="list-group-item list-group-item-action d-flex justify-content-between"
              style={menuItemStyle}
              onClick={() => item.submenu && handleMenuClick(item.key)}
            >
              <div>
                <MenuIcon icon={item.icon} /> {item.title}{" "}
              </div>
              <div>
                {visibleSubmenu != item.key ? (
                  <BiRightArrowAlt />
                ) : (
                  <BiDownArrowAlt />
                )}
              </div>
            </div>

            {item.submenu.map((subItem) => (
              <NavLink
                to={subItem.path}
                key={subItem.key}
                className="list-group-item list-group-item-action"
                hidden={visibleSubmenu != item.key}
              >
                <MenuIcon icon={subItem.icon} /> {subItem.title}
              </NavLink>
            ))}
          </>
        ) : (
          <NavLink
            to={item.path}
            key={item.key}
            className="list-group-item list-group-item-action"
          >
            <MenuIcon icon={item.icon} /> {item.title}
          </NavLink>
        )}
      </div>
    );
  };

  return (
    <>
      <nav className="navbar fixed-top" style={{ background: "white" }}>
        <div className="container-fluid">
          <img className="d-md-none" src={logo} alt="logo" />
          <LuMenuSquare
            style={{ fontSize: "34px", color: "#00a19a" }}
            className="me-3 d-md-none"
            onClick={handleShowSidebar}
          />
          <Offcanvas
            show={showSidebar}
            onHide={handleCloseSidebar}
            backdrop="static"
            style={{}}
          >
            <div className="d-flex justify-content-between">
              <NavLink to="/" className="w-100">
                <img
                  className="pt-3"
                  src={logo}
                  style={{ width: "130px" }}
                  alt="Logo"
                />
              </NavLink>
              <Offcanvas.Header
                style={{ float: "right" }}
                closeButton
              ></Offcanvas.Header>
            </div>
            <hr className="mb-0" />
            <div className="d-flex justify-content-start align-items-center px-3 py-1">
              <div className="pe-3">
                <div className="avatr">
                  <div className="bg-secondary rounded-circle px-2">
                    <span className="fs-4 text-uppercase">
                      <b>{USER_DETAILS.name.slice(0, 1)}</b>
                    </span>
                  </div>
                </div>
              </div>
              <h5 className="nameHeading pt-1">
                <span className="ng-star-inserted">
                  {USER_DETAILS.user_type != "doctor" ? (
                    <>({USER_DETAILS.doctor.name})</>
                  ) : (
                    ""
                  )}
                  {USER_DETAILS.name}
                </span>
              </h5>
            </div>

            <Offcanvas.Body className="p-0 ">
              <div className="d-flex flex-column">
                <div className="text-start position-relative">
                  <div className="list-group dashboard-menu">
                    <div
                      className="d-md-block"
                      style={{
                        borderLeft: "1px solid gray",
                        background: "white",
                        width: "100%",
                      }}
                    >
                      <div className="text-center">
                        <div className="list-group dashboard-menu w-100 text-start">
                          <NavLink className="text-decoration-none ">
                            <NavLink
                              to="/admin/analytical-reports"
                              key="admin/analytical-reports"
                              className="list-group-item list-group-item-action"
                            >
                              <MenuIcon icon={<BiChart />} /> Analysis Report
                            </NavLink>
                            {menu.length > 0 &&
                              menu.map((item, index) =>
                                renderMenuItem(item, index)
                              )}
                          </NavLink>
                          <NavLink
                            to={url}
                            className="list-group-item list-group-item-action py-3"
                            style={{
                              color: "#52a19a",
                              backgroundColor: "white",
                            }}
                          >
                            <BiClinic size={25} className="me-1" /> Website Profile
                          </NavLink>
                          <NavLink
                            to="/"
                            className="list-group-item list-group-item-action py-3"
                            onClick={logout}
                            style={{
                              color: "#52a19a",
                              backgroundColor: "white",
                            }}
                          >
                            <BiLogOut size={25} className="me-1" /> Logout
                          </NavLink>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Offcanvas.Body>
          </Offcanvas>
        </div>
      </nav>
    </>
  );
};

export default Header;
