import React from "react";

export const Terms = () => {
  return (
    <>
      <div className="container">
        <div className="row justify-content-center text-justify">
          <div className="col-md-8">
            <h2 className="text-website text-center">
              Terms & Conditions
            </h2>
            <div className="row mb-5">
              <div className="col-lg-12 col-md-12 mt-lg-5">
                <div id="terms">
                  <h6 className=" text-website">Laws</h6>
                  <ul style={{ listStyleType: "square" }}>
                    <li>Working under Indian IT act 2000</li>
                    <li>Indian contract act 1872</li>
                    <li>Consumer protection act 1986</li>
                    <li>Intellectual property rights act 1957</li>
                  </ul>
                </div>
                <div id="policy" className="py-3">
                  <h5 className="fs-2 text-website mb-4">Terms of use</h5>

                  <h6 className="text-website">Introduction</h6>
                  <p className="pb-3" style={{ lineHeight: "1.8em" }}>
                    This Terms of use Agreement (the "Agreement") is made and
                    entered into as of the Effective Date (as defined below) by
                    and between <a href="mkite.in">mkite.in</a> (the "Owner")
                    and you (the "User").
                  </p>
                  <h6 className="text-website">Effective Date</h6>
                  <p className="pb-3" style={{ lineHeight: "1.8em" }}>
                    The Effective Date of this Agreement is the date on which
                    you first agree to these terms of use by clicking on the "I
                    Agree" button or by otherwise using the Owner's website (the
                    "Website").
                  </p>
                  <h6 className="text-website">Jurisdiction</h6>
                  <p className="pb-3" style={{ lineHeight: "1.8em" }}>
                    This Agreement shall be governed by and construed in
                    accordance with the laws of the State of [UTTAR PRADESH].
                  </p>
                  <h6 className="text-website">Definitions</h6>
                  <p>
                    For the purposes of this Agreement, the following terms
                    shall have the following meanings:
                  </p>
                  <ul>
                    <li className="pb-3" style={{ lineHeight: "1.8em" }}>
                      "Content" means any text, images, audio, video, or other
                      materials that are made available on the Website.
                    </li>
                    <li className="pb-3" style={{ lineHeight: "1.8em" }}>
                      "Intellectual Property Rights" means all patent rights,
                      copyrights, trademark rights, trade secret rights, and
                      other intellectual property rights of any kind.
                    </li>
                    <li className="pb-3" style={{ lineHeight: "1.8em" }}>
                      "Service" means the provision of access to the Website and
                      the Content.
                    </li>
                    <li className="pb-3" style={{ lineHeight: "1.8em" }}>
                      "User" means any individual or entity that accesses or
                      uses the Website.
                    </li>
                  </ul>
                </div>
                <div id="policy" className="py-1">
                  <h5 className="fs-2 text-website mb-4">
                    Rights and Responsibilities of Users
                  </h5>

                  <ul>
                    <li className="pb-3" style={{ lineHeight: "1.8em" }}>
                      Users are granted a non-exclusive, non-transferable,
                      revocable license to access and use the Website and the
                      Content for their own personal and non-commercial use.
                    </li>
                    <li className="pb-3" style={{ lineHeight: "1.8em" }}>
                      Users may not copy, distribute, modify, or create
                      derivative works of any Content without the prior written
                      permission of the Owner.
                    </li>
                    <li className="pb-3" style={{ lineHeight: "1.8em" }}>
                      Users may not copy, distribute, modify, or create
                      derivative works of any Content without the prior written
                      permission of the Owner.
                    </li>
                    <li className="pb-3" style={{ lineHeight: "1.8em" }}>
                      Users are responsible for their own use of the Website and
                      the Content.
                    </li>
                    <li className="pb-3" style={{ lineHeight: "1.8em" }}>
                      The Owner reserves the right to terminate a User's access
                      to the Website and the Content at any time for any reason.
                    </li>
                  </ul>
                  <h5 className="fs-2 text-website">
                    Intellectual Property Rights
                  </h5>
                  <p>
                    The Owner owns all of the Intellectual Property Rights in
                    the Website and the Content. Users are not granted any
                    Intellectual Property Rights in the Website or the Content.
                  </p>
                  <h5 className="fs-2 text-website">Limitation of Liability</h5>
                  <p>
                    The Owner shall not be liable for any damages that Users may
                    suffer as a result of using the Website or the Content,
                    including, but not limited to, direct, indirect, incidental,
                    consequential, or punitive damages.
                  </p>
                  <p>
                    Feature of time tracking of appointment is a doctor/doctor’s
                    staff dependent system based function that requires updates
                    time to time , works through the technology and internet so
                    we show you approximate time and other information, any
                    mismatch in actual time and time showing on website for your
                    appointment and token window (showing token scenario) is
                    possible for which neither company nor doctor will
                    responsible.
                  </p>
                  <h5 className="fs-2 text-website">Termination</h5>
                  <p>
                    The Owner may terminate this Agreement at any time for any
                    reason. Users may terminate this Agreement by discontinuing
                    use of the Website and the Content.
                  </p>
                  <h5 className="fs-2 text-website">
                    Updation of “terms of use”
                  </h5>
                  <p>
                    The Owner has the right to update “terms of use” time to
                    time , we request to user to read “terms of use” time to
                    time to know updating
                  </p>
                </div>

                <h5 className="fs-2 text-website">Contact Information</h5>
                <p className="pb-3" style={{ lineHeight: "1.8em" }}>
                  If you have any questions about this Agreement, please contact
                  the Owner at company’s email address.{" "}
                  <strong>info@mkite.in</strong> .
                </p>
                <p className="pb-3" style={{ lineHeight: "1.8em" }}>
                  Thank you for your time, and we hope Mkite.in proves to be a
                  valuable asset in managing your patient appointments
                  effectively.
                </p>
                <p className="pb-3" style={{ lineHeight: "1.8em" }}>
                  Sincerely, <br></br>
                  The Mkite Team
                </p>
                <hr />
                {/* <button
                  className="btn btn-primary"
                  onClick={() => navigate(-1)}
                >
                  Go Back
                </button> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
