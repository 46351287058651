import React, { useEffect, useState } from "react";
import Header from "./Header";
import { Dashboard } from "./Dashboard";
import Head from "./components/Head";
import { Divider } from "antd";
import Utils from "../../utils/Utils";
import { BiMinus, BiPlus } from "react-icons/bi";
import authService from "../../services/authService";

export const Setting = () => {
  const initialWeekDays = [
    { key: "Mon", title: "Monday", isChecked: false },
    { key: "Tue", title: "Tuesday", isChecked: false },
    { key: "Wed", title: "Wednesday", isChecked: false },
    { key: "Thu", title: "Thursday", isChecked: false },
    { key: "Fri", title: "Friday", isChecked: false },
    { key: "Sat", title: "Saturday", isChecked: false },
    { key: "Sun", title: "Sunday", isChecked: false },
  ];
  const [loading, setLoading] = useState(false);
  const [today, setToday] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [type, setType] = useState("multiple");
  const [multipleDate, setMultipleDate] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [weekDays, setWeekDays] = useState([]);

  const handleCheckboxChange = (key) => {
    setWeekDays((prevWeekDays) =>
      prevWeekDays.map((day) =>
        day.key === key ? { ...day, isChecked: !day.isChecked } : day
      )
    );
  };

  const fatchList = () => {
    setIsLoading(true);
    authService
      .fatchSetting({})
      .then((res) => res.json())
      .then((data) => {
        const d = data.data;
        setWeekDays(
          initialWeekDays.map((day) => ({
            ...day,
            isChecked: d.weekdays?.dates?.includes(day.key),
          }))
        );
      })
      .catch((err) => {
        //console.log("Error fetching the API:", err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    var todate = Utils.todayDate();
    setToday(todate);
    setToDate(todate);
    setMultipleDate([todate]);
    fatchList();
  }, []);

  const handleInputChange = (e) => {
    const { _, value } = e.target;
    setToDate(value);
  };

  const handleTypeChange = (e) => {
    const { _, value } = e.target;
    setType(value);
  };

  const addDates = () => {
    setMultipleDate([...multipleDate, today]);
  };

  const minusDates = (index) => {
    const data = multipleDate.filter((_, i) => i !== index);
    setMultipleDate(data);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData(e.target);
    const values = Object.fromEntries(formData.entries());
    values.dates = formData.getAll("date");
    const resp = authService.updateSetting(values);
    resp
      .then((res) => {
        if (res.status === 200) {
        }
      })
      .catch((err) => {
        console.log("error logs ", err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const setWeeks = weekDays.map((item) => (
    <div className="d-flex mb-3" key={`day_${item.key}`}>
      <input
        type="checkbox"
        name="date"
        value={item.key}
        checked={item.isChecked}
        style={{ width: "25px", height: "25px" }}
        onChange={() => handleCheckboxChange(item.key)}
      />
      <h6 className="ps-4">{item.title}</h6>
    </div>
  ));

  return (
    <div className="dashboard">
      <div
        className="d-md-none"
        style={{
          width: "100%",
          backgroundColor: "black",
          color: "wheat",
          marginBottom: "70px",
        }}
      >
        <Header />
      </div>
      <div className="d-flex">
        <Dashboard />
        <div className="w-100">
          <Head />
          <div className="main-container my-3 px-lg-3 px-1">
            <div className="mt-3">
              <div className="row">
                <div className="col-md-4">
                  <div className="card card-body p-3">
                    <h6>Week off Days</h6>
                    <Divider className="my-0 py-0" />
                    <form className="" onSubmit={handleSubmit}>
                      <input type="hidden" name="type" value="weekdays" />
                      <div className="my-3">{setWeeks}</div>
                      <div className="text-end">
                        <button
                          type="submit"
                          className="btn btn-success"
                          disabled={loading}
                        >
                          {loading ? "Loading..." : "Submit"}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="card card-body p-3">
                    <h6>Set Off Date</h6>
                    <Divider className="my-0 py-0" />
                    <form className="" onSubmit={handleSubmit}>
                      <input type="hidden" name="type" value="offdate" />
                      <div className="my-3">
                        <p className="my-0">Type</p>
                        <select
                          onChange={(e) => handleTypeChange(e)}
                          className="form-control"
                          name="data_type"
                        >
                          <option value="multiple">Multiple</option>
                          <option value="custom">Custom</option>
                        </select>
                      </div>
                      {type == "multiple" &&
                        multipleDate.length > 0 &&
                        multipleDate.map((item, index) => (
                          <div className="my-3 d-flex">
                            <div>
                              <input
                                type="date"
                                name="date"
                                defaultValue={item}
                                className="form-control"
                                pattern="dd-mm-yyyy"
                                min={today}
                              />
                            </div>
                            {index == 0 ? (
                              <button
                                type="button"
                                className="btn btn-warning ms-1"
                                onClick={() => addDates()}
                                style={{ width: "40px" }}
                              >
                                <BiPlus />
                              </button>
                            ) : (
                              <button
                                type="button"
                                className="btn btn-danger ms-1"
                                onClick={() => minusDates(index)}
                                style={{ width: "40px" }}
                              >
                                <BiMinus />
                              </button>
                            )}
                          </div>
                        ))}
                      {type == "custom" && (
                        <>
                          <div className="my-3">
                            <p className="my-0">Date To</p>
                            <input
                              type="date"
                              name="date"
                              defaultValue={today}
                              onChange={(e) => handleInputChange(e)}
                              className="form-control"
                              pattern="dd-mm-yyyy"
                              min={today}
                            />
                          </div>
                          <div className="my-3">
                            <p className="my-0">Date From</p>
                            <input
                              type="date"
                              name="date"
                              defaultValue={toDate}
                              className="form-control"
                              pattern="dd-mm-yyyy"
                              min={toDate}
                            />
                          </div>
                        </>
                      )}
                      <div className="text-end">
                        <button
                          type="submit"
                          className="btn btn-success"
                          disabled={loading}
                        >
                          {loading ? "Loading..." : "Submit"}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="card card-body p-3">
                    <h6>Auto Reserve Token</h6>
                    <Divider className="my-0 py-0" />
                    <form className="" onSubmit={handleSubmit}>
                      <input type="hidden" name="type" value="autoreserve" />
                      <div className="mb-3">
                        <select className="form-control" name="date">
                          <option value="yes">Yes</option>
                          <option value="no">No</option>
                        </select>
                      </div>
                      <div className="text-end">
                        <button
                          type="submit"
                          className="btn btn-success"
                          disabled={loading}
                        >
                          {loading ? "Loading..." : "Submit"}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
