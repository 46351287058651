import React from "react";
import { Card } from "antd";
import ReactApexChart from "react-apexcharts";
const ChartData3 = ({
  title,
  isLoading,
  todayOnline,
  todayOffline,
  type="Offline Patients"
}) => {
  const barChartData = {
    series: [todayOnline || 0, todayOffline || 0],
    options: {
      chart: {
        width: 380,
        type: "pie",
      },
      title: { text: title, align: "left" },
      labels: ["Online Patients", type],
      dataLabels: { enabled: true },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
  };
  return (
    <Card
      style={{
        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
        borderRadius: "8px",
      }}
      bodyStyle={{ padding: "15px 0px 11px 0px" }}
      loading={isLoading}
    >
      <ReactApexChart
        options={barChartData.options}
        series={barChartData.series}
        type="pie"
        height={300}
      />
    </Card>
  );
};

export default ChartData3;
