import React from "react";

export const Privacy = () => {
  return (
    <>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-8">
            <h2 className="text-website text-center">Privacy Policy</h2>
            <div className="row mb-5">
              <div className="col-lg-12 col-md-12 mt-3 mt-lg-5">
                <div id="policy" className="">
                  <h6 className="text-website h4">Introduction</h6>
                  <p className="py-3" style={{ lineHeight: "1.8em" }}>
                    Mkite.in (“Company” or “We”) respect your privacy and are
                    committed to protecting it by complying with this policy.
                  </p>
                  <h6 className="text-website">
                    Information we collect when you use Website:
                  </h6>
                  <p className="pb-3" style={{ lineHeight: "1.8em" }}>
                    * We collect data (name, mobile no., email address, age,
                    gender, city) from user (patient).
                  </p>
                  <h6 className="text-website">How We collect information:</h6>
                  <p className="pb-3" style={{ lineHeight: "1.8em" }}>
                    We collect user’s(patient) information (name, mobile no.,
                    email address, gender, age, city) during the online “token
                    generation”.
                  </p>
                  <h6 className="text-website">
                    How we use information we collect:
                  </h6>
                  <p>
                    We use these data to Send message to patient to make aware
                    of Token Booking Number, To Improve and Provide our services
                    to users.
                  </p>
                </div>

                <h5 className="fs-2 text-website">
                  How we share information we collect:
                </h5>
                <p className="" style={{ lineHeight: "1.8em" }}>
                  We share user (patient) information with Only Doctor for which
                  user are generating token.
                </p>
                <p className="pb-3" style={{ lineHeight: "1.8em" }}>
                  We don't share any kind of information related to our users to
                  third party.
                </p>

                {/* ============== */}
                <h6 className="text-website">
                  User control over their information:
                </h6>
                <p>
                  We take care of our users so we take care of their personal
                  information as well as and use best technology to protect it
                  from unauthorized access, use, or disclosure.
                </p>
                <p>
                  We collect or use all the information of users after the
                  permission.
                </p>
                <h6 className="text-website">
                  Changes to This Privacy Policy:
                </h6>
                <p>
                  We update our privacy policies regularly to provide quality
                  experience of users Effective Date
                </p>
                <p>
                  This Privacy Policy is effective as of date you started use
                  website.
                </p>

                <h5 className="fs-2 text-website mt-5">Contact Us</h5>
                <p>
                  If you have any questions about this Privacy Policy, please
                  contact us at{" "}
                  <a href="mailto:mkiteindia@gmail.com">mkiteindia@gmail.com</a>
                  .
                </p>
                <div className="mt-3">
                  {/* <button
                    className="btn btn-primary"
                    onClick={() => navigate(-1)}
                  >
                    Go Back
                  </button> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
